@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* border: 1px solid red; */
}

html,
body {
    font-family: 'Inter', sans-serif;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.7;
}

a {
    text-decoration: none;
    color: #fff;
    display: inline-block;
}

h1,
h2,
h3,
h4,
h5 {
    color: #fff;
}

.btn {
    padding: 24px 32px;
}

.section {
    padding: 0 60px;
}

:root {
    --purple: #8437FA;
}

/* wrapper */
.wrapper {
    background: linear-gradient(90deg, #000 37.72%, #8437FA 255.03%);
    width: 100%;
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
}

/* navbar */
nav {
    width: 100%;
    height: 115px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 60px;
    padding-right: 60px;
}

nav>div {
    display: flex;
    align-items: center;
    position: relative;
}

nav>div img {
    width: 40px;
    height: 40px;
}

nav .home {
    color: var(--purple);
    font-weight: 600;
    font-weight: 600;
    padding-left: 212px;
    margin-right: 40px;
}



nav .button {
    background-color: var(--purple);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Percent */
.percent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 33px 0;
    border-bottom: 1px solid gray;
    width: 100%;
}

.percent .ther {
    display: none;
}

/* Hero */
.hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 56px;
    margin-bottom: 173px;
    width: 100%;
}

.hero .hero_left h1 {
    font-size: 64px;
    font-weight: 500;
    color: #fff;
    width: 70%;
    text-transform: uppercase;
}

.hero .hero_left p {
    width: 80%;
    font-size: 24px;
    opacity: 0.7;
    padding-top: 32px;
    text-transform: capitalize;
    padding-bottom: 48px;
    line-height: 36px;
}

.hero .btn1 {
    background-color: var(--purple);
    display: inline-block;
}

.hero .btn2 {
    display: inline-block;
    border: 1px solid var(--purple);
    margin-left: 26px;
}

.hero .hero_right img {
    width: 600px;
    height: 459px;
    padding-right: 22px;
    object-fit: cover;
}

/* Security */
.security {
    padding: 120px 60px;
    border: 1px solid #fff;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0 60px;
    /* width: 100%; */
}

.security .sec_img {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.security .sec_left {
    margin-right: 68px;
}

.security .sec_left h1 {
    width: 100%;
    font-weight: 500;
    font-size: 48px;
    text-transform: uppercase;
    color: #fff;
    padding-bottom: 24px;
}

.security .sec_left p {
    width: 100%;
    opacity: 0.7;
}

.security .sec_right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
}

.security .sec_right .sec_1 {
    width: 47%;
    padding: 24px 16px;
    border: 1px solid rgba(137, 132, 132, 0.4);
}

.security .sec_right .sec_1>div {
    display: flex;
    justify-content: space-between;
}

.sec_right img {
    width: 100%;
    width: 32px;
    height: 32px;
    /* background: url(<path-to-image>), rgb(55, 41, 41) 0px -2px / 100% 100% no-repeat; */
    padding: 8px;
    border: 1px solid rgba(137, 132, 132, 0.4);
}

.sec_right h3 {
    font-size: 40px;
    font-weight: 600;
}

.sec_right h4 {
    padding-top: 24px;
    padding-bottom: 16px;
    font-weight: 600;
}

.sec_right p {}


/* Priority */
.priority {
    margin-top: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 60px;
    padding-right: 60px;
}

.prio_left {
    width: 50%;
}

.prio_left h1 {
    font-size: 48px;
    width: 80%;
    text-transform: uppercase;
    font-weight: 500;
}

.prio_left p {
    width: 90%;
    padding-top: 24px;
    padding-bottom: 32px;
}

.prio_left a {
    background-color: var(--purple);
}

.prio_right {
    width: 50%;
}

.prio_right img {
    /* width: 80px; */
    height: 80px;
    border-right: 1px solid #fff;
    padding-right: 30px;
    margin-right: 30px;
}

.prio_right .div {
    display: flex;
    width: 100%;
    height: 140px;
    align-items: center;
    border: 1px solid #fff;
    margin-bottom: 24px;
    padding: 30px;
}

.prio_right h3 {
    font-size: 32px;
    font-weight: 500;
}

.prio_right p {
    font-weight: 400;
    opacity: 0.7;
    padding-top: 12px;
}

.bitcoin {
    position: absolute;
    width: 742px;
    height: 742px;
    right: -60px;
    top: 2200px;
    z-index: 0;
}


/* Testimonials */
.testimonial {
    margin-top: 200px;
    border: 1px solid #fff;
    border-radius: 70px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 60px;
    margin-right: 60px;
    padding-top: 120px;
    padding-bottom: 192px;
    position: relative;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.04);
}

.testimonial .vector {
    position: absolute;
    top: -15px;
    left: -15px;
    width: 32px;
    height: 32px;
}

.testimonial .person {
    width: 160px;
    height: 160px;
    margin-bottom: 16px;
}

.testimonial h5 {
    font-size: 12px;
    font-weight: 400;
}

.testimonial h4 {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.testimonial p {
    margin-top: 64px;
    font-size: 24px;
    font-weight: 500;
    width: 60%;
    text-transform: uppercase;
    opacity: 0.7;
    text-align: center;
    line-height: 36px;
}

.testimonial .coat1 {
    width: 87.411px;
    height: 72px;
    position: absolute;
    left: 137px;
    top: 330px;
}

.testimonial .coat2 {
    width: 87.411px;
    height: 72px;
    position: absolute;
    top: 546px;
    right: 137px;
}


/* Community */
.communities {
    position: relative;
}

.community {
    margin-top: 200px;
    border: 1px solid #fff;
    border-radius: 0px 70px 0px 0px;
    margin-left: 60px;
    margin-right: 60px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 60px;
    z-index: 9999999;
    /* clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0); */
}

/* .communities::before {
    content: '';
    display: block;
    position: absolute;
    background: rgba(132, 55, 250, 0.20);
    filter: blur(72px);
    width: 100%;
    height: 244px;
    transform: rotate(-10deg);
    border-radius: 150px;
} */

.community .vector {
    display: flex;
    align-items: center;
    margin-bottom: 49px;
    margin-top: 114px;
}

.community .p_logo {
    width: 33px;
    height: 33px;
    border: 1px solid #fff;
    padding: 1px;
}

.community h3 {
    margin-left: 16px;
    font-size: 32px;
    font-weight: 400;
}

.community .tele {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.tele .telegram {
    display: flex;
    align-items: center;
    width: 48%;
    height: 240px;
    border: 1px solid #fff;
    padding: 60px 32px;
}

.tele img {
    margin-right: 24px;
}

.tele h2 {
    font-size: 60px;
    font-weight: 600;
    padding-bottom: 16px;
}




/* Footer */
footer {
    display: flex;
    margin-top: 120px;
    margin-left: 60px;
    margin-right: 78px;
    margin-bottom: 20px;
}

footer p {
    width: 70%;
    line-height: 20px;
}

footer img {
    margin-top: 98px;
    width: 32px;
    height: 32px;
}

footer ul {
    list-style: none;
}

footer .f_right {
    display: flex;
    justify-content: space-between;
    /* margin-left: 248px; */
    width: 100%;
}

footer .f_right .resources,
footer .f_right .legal,
footer .f_right .brain {
    width: 25%;
}



footer h3 {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 24px;
}

footer ul li {
    padding-bottom: 16px;
    opacity: 0.7;
}


/* bottom footer */
.b_footer {
    border-top: 1px solid #fff;
    padding: 30px 60px;
    display: flex;
    justify-content: space-between;
}


.b_footer .icon {
    display: flex;
    gap: 12px;
}

.b_footer .icon .img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid rgba(246, 245, 247, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.b_footer .icon img {
    width: 16px;
    height: 16px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
